import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Linux körs på Mars och kanske till och med på Googles nya Fuschia OS. Lastpass stryper gratiskonton och Trevlig Mjukvara börjar undersöka vad det mystiska Gemini egentligen är. Två fräscha trevligheter och mycket mer!`}</p>
    <h2>{`Nyheter`}</h2>
    <h3>{`Kort & Gott`}</h3>
    <ul>
      <li parentName="ul">{`Linux kernel 5.11:`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://kernelnewbies.org/Linux_5.11"
            }}>{`https://kernelnewbies.org/Linux_5.11`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://old.reddit.com/r/linux/comments/ljzl92/the_511_kernel_is_out/"
            }}>{`https://old.reddit.com/r/linux/comments/ljzl92/the_511_kernel_is_out/`}</a></li>
        </ul></li>
      <li parentName="ul">{`GNOME undersöker: `}<a parentName="li" {...{
          "href": "https://blogs.gnome.org/shell-dev/2021/02/15/shell-ux-changes-the-research/"
        }}>{`https://blogs.gnome.org/shell-dev/2021/02/15/shell-ux-changes-the-research/`}</a></li>
      <li parentName="ul">{`RISC-V PINE64 SBC: `}<a parentName="li" {...{
          "href": "https://www.pine64.org/2021/02/15/february-update-show-and-tell/"
        }}>{`https://www.pine64.org/2021/02/15/february-update-show-and-tell/`}</a></li>
      <li parentName="ul">{`Lastpass stryper gratiskonton (tack Marcus):`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://blog.lastpass.com/2021/02/changes-to-lastpass-free/"
            }}>{`https://blog.lastpass.com/2021/02/changes-to-lastpass-free/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.sweclockers.com/nyhet/31378-lastpass-stryper-gratiskonton-kan-endast-anvandas-pa-en-typ-av-enhet"
            }}>{`https://www.sweclockers.com/nyhet/31378-lastpass-stryper-gratiskonton-kan-endast-anvandas-pa-en-typ-av-enhet`}</a></li>
        </ul></li>
      <li parentName="ul">{`Linuxappar i Fuchsia OS: `}<a parentName="li" {...{
          "href": "https://9to5google.com/2021/02/12/google-fuchsia-os-android-linux-programs-starnix/"
        }}>{`https://9to5google.com/2021/02/12/google-fuchsia-os-android-linux-programs-starnix/`}</a></li>
      <li parentName="ul">{`Linux på Mars: `}<a parentName="li" {...{
          "href": "https://www.zdnet.com/article/to-infinity-and-beyond-linux-and-open-source-goes-to-mars/"
        }}>{`https://www.zdnet.com/article/to-infinity-and-beyond-linux-and-open-source-goes-to-mars/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`github1s: `}<a parentName="li" {...{
          "href": "https://github1s.com/microsoft/vscode"
        }}>{`https://github1s.com/microsoft/vscode`}</a></li>
      <li parentName="ul">{`Tangram: `}<a parentName="li" {...{
          "href": "https://github.com/sonnyp/Tangram"
        }}>{`https://github.com/sonnyp/Tangram`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Chatgate: Telegram > XMPP > IRC`}</li>
      <li parentName="ul">{`Trevlig Mjukvara går Gemini`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Chokad Alex`}</li>
      <li parentName="ul">{`Spot, tack bobodrone`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`YouTube: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Matrix: `}<a parentName="li" {...{
          "href": "https://matrix.to/#/%23TrevligMjukvara:matrix.org"
        }}>{`#TrevligMjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Backbay Lounge" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      